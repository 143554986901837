<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="capacity" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="capacity.id">
        <getecma-header size="lg">Editar Unidade de Medida</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-capacity-edit-information @save="save" />
      </div>
      <div v-if="!capacity_id">
        <getecma-header size="lg">Adicionar Unidade de Medida</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-capacity-create-information @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess } from '@/services/toastService';
import { goToOpenCapacity, goToCapacityForbiddenPage } from '@/modules/capacity/capacity.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getCapacityById, updateCapacityInformation, createNewCapacity } from '@/modules/capacity/capacity.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { CAPACITIES_URL } from '@/modules/capacity/capacity.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaCapacityEditInformation from '@/modules/capacity/components/CapacityEditComponent.vue';
import GetecmaCapacityCreateInformation from '@/modules/capacity/components/CapacityCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaCapacityEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaCapacityEditInformation,
    GetecmaCapacityCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const capacityEditVm = {};
    Object.defineProperty(capacityEditVm, 'capacity', {
      get: () => this.capacity,
    });
    return { capacityEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Unidades de Medida', path: CAPACITIES_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      currentUser: getters.getUser(),
      capacity: null,
      createdCapacity: null,
      fullscreenLoading: false,
    };
  },
  computed: {
    capacity_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.capacity_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToCapacityForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToCapacityForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.capacity_id) {
        getCapacityById(this.capacity_id)
          .then(data => {
            this.capacity = data;
          })
          .catch(() => toastError('Erro ao obter nidade de medida por ID'));
      } else {
        this.capacity = {
          name: '',
        };
      }
    },
    save() {
      if (this.capacity_id) {
        this.fullscreenLoading = true;
        updateCapacityInformation(this.capacity)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Unidade de medida salva!');
            goToOpenCapacity(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o unidade de medida');
            this.fullscreenLoading = false;
          });
      } else {
        this.onCreateNewCapacity(this.capacity);
      }
    },
    onCreateNewCapacity(capacity) {
      createNewCapacity(capacity)
        .then(data => {
          goToOpenCapacity(this.$router, data);
        })
        .catch(() => {
          toastError('Erro ao salvar a unidade de medida');
        });
    },
  },
};
</script>
