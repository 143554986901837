<template>
  <div>
    <getecma-form v-if="capacity" :submit="save">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="capacity.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Baixo" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Tipo*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="tipo"
            rules="required"
            placeholder="Tipo da Unidade de Medida"
            :value="capacity.type === 0 ? 'Coleta' : 'Veículo'"
            :options="typeOptions"
            @on-change="onChangeType">
          </getecma-select>
        </div>
      </div>
      <div v-if="capacityByCollect" class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="capacity.duration"
            label="Duração (minutos)*"
            name="duração"
            type="number"
            rules="required"
            placeholder="ex.: 30" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="kilos"
            label="Quilos (peso)*"
            name="quilos"
            type="number"
            rules="required"
            placeholder="ex.: 6"
          @input="onChangeKilos()" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="liters"
            label="Litros (volume)*"
            name="litros"
            type="number"
            rules="required"
            placeholder="ex.: 30"
            @input="onChangeLiters()" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl">Conversão automática?</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <el-switch v-model="convert" active-text="Sim" inactive-text="Não" />
        </div>
      </div>
      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Salvar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { TYPE_CAPACITY } from '@/modules/capacity/capacity.constants';

export default {
  name: 'GetecmaCapacityCreateInformation',
  components: {},
  inject: ['capacityEditVm'],
  data() {
    return {
      capacity: this.capacityEditVm.capacity,
      performer: getters.getUser(),
      changeKilosLiters: false,
      capacityByCollect: true,
      type: [0, 1],
      kilos: null,
      liters: null,
      convert: true,
    };
  },
  computed: {
    typeOptions() {
      return this.type.map(t => ({
        key: t,
        value: TYPE_CAPACITY[t],
      }));
    },
  },
  mounted() {
    if (this.capacity.type === 1) {
      this.capacityByCollect = false;
    } else {
      this.capacityByCollect = true;
    }
    this.kilos = this.capacity.kilos;
    this.liters = this.capacity.liters;
  },
  methods: {
    goHistoryBack,
    save() {
      if (this.changeKilosLiters) {
        this.capacity.kilos = this.kilos.replace(',', '.');
        this.capacity.liters = this.liters.replace(',', '.');
      }
      this.$emit('save');
    },
    onChangeKilos() {
      if (this.convert) {
        this.liters = (this.kilos * 6).toFixed(2);
      }
      this.changeKilosLiters = true;
    },
    onChangeLiters() {
      if (this.convert) {
        this.kilos = (this.liters / 6).toFixed(2);
      }
      this.changeKilosLiters = true;
    },
    onChangeType(type) {
      this.capacity.type = type.key;
      if (type.key === 1) {
        this.capacity.duration = 0;
        this.capacityByCollect = false;
      } else {
        this.capacity.duration = '';
        this.capacityByCollect = true;
      }
    },
  },
};
</script>
